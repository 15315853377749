import { closeApexAccount } from "api/accounts";
import { syncToHubspot } from "api/hubspot";
import { blockUser, unblockUser } from "api/users";
import useUser from "components/Hooks/User.hooks";

export default function useOverflowMenuDialog(
  userId: string,
  closeDialog: () => void,
  onEdit: () => void
) {
  const user = useUser(userId);

  const toggleBlocked = () => {
    if (user) {
      user.blocked
        ? unblockUser(userId).subscribe(onEdit)
        : blockUser(userId).subscribe(onEdit);
      closeDialog();
    }
  };

  const closeAccount = () => {
    closeApexAccount(userId).subscribe(onEdit);
  };

  const syncHubspot = () => {
    syncToHubspot([userId]);
  };

  const sendChat = () => {
    window.open(
      `/chat/${userId}-advisor-channel`,
      "_blank",
    );
  };

  return { user, toggleBlocked, closeAccount, syncHubspot, sendChat };
}
