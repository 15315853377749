import { Box } from "@mui/material";
import Spacer from "components/Common/Spacer";
import AccountActivity from "components/Details/AccountActivity/AccountActivity";
import AccountBalances from "components/Details/AccountBalances/AccountBalances";
import AccountOverview from "components/Details/AccountOverview/AccountOverview";
import FundingAccount from "components/Details/FundingAccount/FundingAccount";
import { ApexAccountType } from "models/ApexAccount";
import PortfolioBreakdown from "./PortfolioBreakdown/PortfolioBreakdown";
import RecurringDeposits from "components/Details/RecurringDeposits/RecurringDeposits";
import PurchaseRoundUps from "./PurchaseRoundUps/PurchaseRoundUps";
import RiskProfile from "./RiskProfile/RiskProfile";
import UserDetailSection from "views/Users/UserDetailPage/components/UserDetailSection";

interface IProps {
  userId: string;
}

export default function InvestTabView({ userId }: IProps) {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="flex-start"
      flexDirection="column"
      width="100%"
    >
      <UserDetailSection heading="Overview">
        <AccountOverview userId={userId} type={ApexAccountType.Investment} />
      </UserDetailSection>
      <Spacer height={32} />
      <UserDetailSection heading="Investing Balances">
        <AccountBalances userId={userId} type={ApexAccountType.Investment} />
      </UserDetailSection>
      <Spacer height={32} />
      <UserDetailSection heading="Activity">
        <AccountActivity userId={userId} type={ApexAccountType.Investment} />
      </UserDetailSection>
      <Spacer height={32} />
      <UserDetailSection heading="Funding Account">
        <FundingAccount userId={userId} type={ApexAccountType.Investment} />
      </UserDetailSection>
      <Spacer height={32} />
      <UserDetailSection heading="Portfolio Breakdown">
        <PortfolioBreakdown userId={userId} />
      </UserDetailSection>
      <Spacer height={32} />
      <UserDetailSection heading="Recurring Deposits">
        <RecurringDeposits userId={userId} type={ApexAccountType.Investment} />
      </UserDetailSection>
      <Spacer height={32} />
      <UserDetailSection heading="Purchase Round-Ups">
        <PurchaseRoundUps userId={userId} />
      </UserDetailSection>
      <Spacer height={32} />
      <UserDetailSection heading="Risk Profile">
        <RiskProfile userId={userId} />
      </UserDetailSection>
    </Box>
  );
}
