import React from "react";
import { TableContainer, Paper } from "@mui/material";
import usePortfolioBreakdown from "./PortfolioBreakdown.hooks";
import CustomTable from "components/Table/CustomTable";

interface IProps {
  userId: string;
}

export default function PortfolioBreakdown({ userId }: IProps) {
  const { portfolio } = usePortfolioBreakdown(userId);

  const rows = (portfolio || []).map((model) => ({
    cells: [
      { value: model.name },
      { value: `${model.allocation}%` },
      { value: `${model.target}%` },
    ],
  }));

  return (
    <TableContainer component={Paper}>
      <CustomTable headings={["Slice", "Allocation", "Target"]} rows={rows} />
    </TableContainer>
  );
}
