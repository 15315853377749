import { Button } from "@mui/material";
import SuperAdminAction from "components/SuperAdmin/SuperAdminAction";

interface IProps {
  onClick: () => void;
}

export default function SendTextButton({ onClick }: IProps) {
  return (
    <SuperAdminAction
      renderComponent={(onClick) => {
        return (
          <Button fullWidth variant="contained" onClick={onClick}>
            Send Text
          </Button>
        );
      }}
      action={onClick}
    />
  );
}
