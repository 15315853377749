import { getAdvisorArchPortfolioByUserId } from "api/investments";
import { AdvisorArchPortfolioRow } from "models/advisor_arch/AdvisorArchSecurityModel";
import { useEffect, useState } from "react";
import { fetcher } from "utils/data";

export default function usePortfolioBreakdown(userId: string) {
  const [portfolio, setPortfolio] = useState<AdvisorArchPortfolioRow[]>();

  const fetchPortfolio = fetcher(
    getAdvisorArchPortfolioByUserId,
    [userId],
    setPortfolio
  );

  useEffect(() => {
    fetchPortfolio();
  }, [userId]);

  return { portfolio };
}
