import useAllAccountsTabPanel from "./AllAccountsTabPanel.hooks";
import { TabPanel } from "@mui/lab";
import { OfficerComplianceTab } from "../../OfficerCompliancePage";
import { Typography } from "@mui/material";
import Spacer from "components/Common/Spacer";
import TransfersTable from "../TransfersTable";
import TransfersTableRow from "../TransferRow";
import { UserInvestmentTransaction } from "../../../../models/plaid/Transaction";

export default function AllAccountsTabPanel() {
  const {loading, page, setPage, transactions, totalCount, transactionsPerPage} = useAllAccountsTabPanel();

  return (
    <TabPanel key={OfficerComplianceTab.AllAccounts} value={OfficerComplianceTab.AllAccounts}>
      <Typography variant="h6">Transactions</Typography>
      <Spacer height={12}/>
      <TransfersTable
        loading={loading}
        page={page}
        onPageChange={setPage}
        rowCount={totalCount}
        rowsPerPage={transactionsPerPage}>
        {transactions.map(e =>
          <TransfersTableRow<UserInvestmentTransaction>
            model={e}
            subhead2Getter={(model) => model.userName}
          />
        )}
      </TransfersTable>
    </TabPanel>
  );
}
