import { TableCell, TableRow, Typography } from "@mui/material";
import DialogModal from "../../../components/Modals/DialogModal";
import { formatUTCDate } from "../../../utils/time";
import TransactionDetailsDialog from "./TransactionDetailsDialog";
import { noop } from "lodash";
import { InvestmentTransaction } from "../../../models/plaid/Transaction";

interface IProps<T extends InvestmentTransaction> {
  model: T;
  subhead2Getter: (model: T) => string;
}

export default function TransfersTableRow<T extends InvestmentTransaction>({model, subhead2Getter}: IProps<T>) {
  return (
    <DialogModal
      PaperProps={{
        sx: {
          width: {xs: "100%", sm: "440px"}
        }
      }}
      renderDisplay={(open, setOpen) => (
        <TableRow key={model.investmentTransactionId} onClick={() => setOpen(!open)}>
          <TableCell>
            <Typography fontWeight="bold">{formatUTCDate(model.date)}</Typography>
            {model.name}<br/>
            {subhead2Getter(model)}<br/>
          </TableCell>
        </TableRow>
      )}
      renderDialog={(open, setOpen) => <TransactionDetailsDialog
        close={() => setOpen(!open)}
        model={model}/>}
      onClose={noop}
    />
  );
}
