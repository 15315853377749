import { useEffect, useState } from "react";
import { fetcher } from "utils/data";
import { getAccounts, getExpiredAccessTokenInstitutions, getInvestmentTransactions } from "../../../../api/plaid";
import { InvestmentTransactions } from "../../../../models/plaid/Transaction";
import { Institution } from "../../../../models/plaid/Institution";
import { Account } from "../../../../models/plaid/Account";

export default function useMyAccountsTabPanel(recentlyConnectedInstitutions: number) {
  const transactionsPerPage = 5;

  const [accounts, setAccounts] = useState<Account[]>();
  const [expiredAccessTokenInstitutions, setExpiredAccessTokenInstitutions] = useState<Institution[]>();
  const [page, setPage] = useState<number>(0);
  const [transactions, setTransactions] = useState<InvestmentTransactions>();

  const fetchAccounts = fetcher(getAccounts, [], setAccounts);

  const fetchExpiredAccessTokenInstitutions = fetcher(getExpiredAccessTokenInstitutions, [], setExpiredAccessTokenInstitutions);

  const fetchTransactions = fetcher(getInvestmentTransactions, [transactionsPerPage, page * transactionsPerPage], setTransactions);

  useEffect(() => {
    fetchAccounts();
    fetchExpiredAccessTokenInstitutions();
  }, []);

  useEffect(() => {
    setAccounts(undefined);
    fetchAccounts();
  }, [recentlyConnectedInstitutions]);

  useEffect(() => {
    fetchTransactions();
  }, [page]);

  const onConnectionUpdated = (itemId: string) => {
    setAccounts(undefined);
    fetchAccounts();
    if (!expiredAccessTokenInstitutions) {
      return;
    }
    const index = expiredAccessTokenInstitutions.findIndex(e => e.itemId === itemId);
    if (index === -1) {
      return;
    }
    const institutions = [...expiredAccessTokenInstitutions];
    institutions.splice(index, 1);
    setExpiredAccessTokenInstitutions(institutions);
  };

  return {
    accounts: accounts ?? [],
    accountsLoading: accounts == undefined,
    expiredAccessTokenInstitutions: expiredAccessTokenInstitutions ?? [],
    onConnectionUpdated,
    page,
    setPage,
    totalCount: transactions?.totalCount ?? 0,
    transactions: transactions?.transactions ?? [],
    transactionsLoading: transactions === undefined,
    transactionsPerPage,
  };
}
